<template>
	<Popup title="Signup" :other-parts="[Login2PopupPart]">
		<form class="flex flex-col gap-4">
			<FormGroup
					v-model:parent_ref="formData.username"
					label="Username"
					autocomplete="username"
					placeholder="Username"
					:check="(value) => {
						return value.length >= fieldLimits.username.min && value.length <= fieldLimits.username.max && /^[a-zA-Z0-9._-]+$/.test(value);
                    }"/>
			<FormGroup
					v-model:parent_ref="formData.email"
					label="Email"
					type="email"
					autocomplete="email"
					placeholder="Email"
					:check="(value) => {
                        return value.length <= fieldLimits.email.max && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
                    }"/>
			<FormGroup
					v-model:parent_ref="formData.password"
					label="Password"
					type="password"
					autocomplete="new-password"
					placeholder="Password"
					:check="(value) => {
						return value.length >= fieldLimits.password.min && value.length <= fieldLimits.password.max && /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/.test(value);
                    }"/>
			<FormGroup
					v-model:parent_ref="formData.confirm_password"
					label="Confirm Password"
					type="password"
					autocomplete="new-password"
					placeholder="Password"
					:check="(value) => {
                        return value === formData.password;
                    }"/>
			<HCaptcha />
			<p class="text-gray-850 text-sm">
				By continuing, you agree to the
				<NuxtLink class="text-bold text-black text-sm" to="/terms-and-conditions">
					Terms & Conditions
				</NuxtLink>
				and
				<NuxtLink class="text-bold text-black" to="/privacy-policy">
					Privacy Policy
				</NuxtLink>
			</p>
			<div class="bg-gray-200 w-full h-px"></div>
			<PopupsComponentsPopupButton
					text="Signup"
					:action="register"
					:arrow="null"
			/>
		</form>
	</Popup>
</template>

<script setup>
import { usePopup } from "@/composables/usePopup";
import { notify } from "@kyvg/vue3-notification";
import Login2PopupPart from '~/components/popups/parts/Login2PopupPart.vue';

const { setPopup } = usePopup();

// register

const formData = reactive({
	username: "",
	email: "",
	password: "",
	confirm_password: "",
	captcha: null
});

const loading = ref(false);

const register = async () => {
	if (loading.value) {
		return;
	}

	const iframe = document.querySelector(".captcha iframe");
	if (!iframe) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	const captchaResponse = iframe.getAttribute("data-hcaptcha-response");
	formData.captcha = captchaResponse;

	if (!captchaResponse) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	try {
		loading.value = true;

		const response = await $fetch("/api/user/register", {
			method: "POST",
			body: formData,
			ignoreResponseError: true
		});

		if (response.status) {
			notify({
				type: "success",
				title: "Registered successfully"
			})

			setPopup('LoginPopup');
		} else if (response.error) {
			notify({
				type: "error",
				title: response.error
			})
		} else {
			notify({
				type: "error",
				title: "Error occured"
			})
		}
	} catch (e) {
		notify({
			type: "error",
			title: "Something went wrong"
		})
	} finally {
		loading.value = false;
	}
};
</script>