<template>
	<h4 class="text-center">Already have an account?</h4>
	<PopupsComponentsPopupButton
			text="Login"
			:arrow="null"
			color="bg-green-300 hover:bg-green-600"
			classes="border border-green-600"
			:action="openPopup"
	/>
</template>

<script setup>
const { setPopup } = usePopup();

const openPopup = () => {
	setPopup('LoginPopup');
}
</script>